<template>
  <footer class="footer">
    <div class="primary button-group pagination-button-group">
      <woot-button
        size="small"
        variant="smooth"
        color-scheme="secondary"
        :is-disabled="hasPrevPage"
        @click="onPrevPage"
      >
        <fluent-icon icon="chevron-left" size="18" />
      </woot-button>
      <woot-button
        size="small"
        variant="smooth"
        color-scheme="secondary"
        @click.prevent
      >
        {{ currentPage }}
      </woot-button>
      <woot-button
        size="small"
        variant="smooth"
        color-scheme="secondary"
        :is-disabled="!hasNextPage"
        @click="onNextPage"
      >
        <fluent-icon icon="chevron-right" size="18" />
      </woot-button>
    </div>
    <div class="page-meta">
      <strong>{{ firstIndex }}</strong>
      - <strong>{{ lastIndex }}</strong>
      <template v-if="hasTotalCount">
        of <strong>{{ totalCount }}</strong>
      </template>
      items
    </div>
  </footer>
</template>

<script>
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {},
  mixins: [rtlMixin],
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    itemsCount: {
      type: Number,
      default: 0,
    },
    hasMoreItems: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pageFooterIconClass() {
      return this.isRTLView
        ? 'margin-right-minus-slab'
        : 'margin-left-minus-slab';
    },
    firstIndex() {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      return this.hasMoreItems
        ? this.pageSize * this.currentPage
        : this.pageSize * (this.currentPage - 1) + (this.itemsCount || 0);
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    hasNextPage() {
      return this.hasMoreItems;
    },
    hasPrevPage() {
      return this.currentPage === 1;
    },
    hasTotalCount() {
      return typeof this.totalCount !== 'undefined';
    },
  },
  methods: {
    onNextPage() {
      if (!this.hasNextPage) {
        return;
      }
      const newPage = this.currentPage + 1;
      this.onPageChange(newPage);
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        return;
      }
      const newPage = this.currentPage - 1;
      this.onPageChange(newPage);
    },
    onFirstPage() {
      const newPage = 1;
      this.onPageChange(newPage);
    },
    onPageChange(page) {
      this.$emit('page-change', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 60px;
  border-top: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  padding: 0 var(--space-normal);
}
.page-meta {
  font-size: var(--font-size-mini);
  margin-left: var(--space-small);
}
</style>
